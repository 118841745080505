import React from "react";
import styles from "../LandingPage/LandingBody.module.css";
import ContactUsFrom from "../common/ContactUsFrom";
import waveback from "../assests/howitworkswave.png"
export default function HowItWorks() {
  return (
    <>
    <div className={styles.topbannerHowMobile} style={{display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  marginTop: "6rem",
  marginBottom: "4rem",
  backgroundImage: `url(${waveback})`, 
  backgroundSize: "cover", 
  backgroundPosition: "center", 
  width: "100%", 
  minHeight: "400px"}}>
    <h2 style={{color:"#001A2E", fontSize:"2.5rem"}}>How it works?</h2>
    <p style={{color:"#003353", fontSize:"1rem", maxWidth:"50rem",textAlign:"center"}}>Share Your Insights with Confidence With processing times of less than 10 seconds, you'll breeze through your coding tasks in no time. 
            </p>
    </div>
     <div className={styles.mainGridHow} style={{
       // Needed to position the vertical line
    }}>
      {/* Vertical dashed line */}
      <div className={styles.dashedLineMobile} style={{
  position: 'absolute',
  top: 0,
  left: '50%',
  borderLeft: '2px dashed #D9D9D9',
  height: 'calc((185px * 5) + (20px * 4))', // Example height for 5 items and 4 gaps
  zIndex: 0
}}></div>
      
      {/* Item 1 */}
      <div></div> {/* Empty div for spacing */}
      <div style={{
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnStart: 2,
        gridRowStart: 1,
        zIndex: 1,
        
        
      }}><p style={{color:"#8C8C8C"}}>1</p></div>
      <div style={{
        padding: '25px',
        borderRadius:"15px",
        border: '1px solid #E9E9E9',
        gridColumnStart: 1,
        gridRowStart: 1,
        
      }}>
        <div style={{display:"flex", flexDirection:"column", rowGap:"1rem"}}>
        <p style={{color : "#0065A6"}}>Step 1</p>
        <h3 style={{color:"#00263E", fontWeight:"bold"}}>Effortless Upload</h3>
        <p style={{color:"#5D5D5D"}}>With NoteDoctor.AI's drag and drop system, uploading files for processing is a breeze. Simply drag your files into the designated area, sit back, and let the magic unfold. It's like having a personal assistant handle the paperwork, freeing up your time for more important tasks.</p>
        </div>
      </div>

      {/* Item 2 */}
      <div></div> {/* Empty div for spacing */}
      <div style={{
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnStart: 2,
        gridRowStart: 2,
        zIndex: 1
      }}><p style={{color:"#8C8C8C"}}>2</p></div>
      <div style={{
        padding: '25px',
        borderRadius:"15px",
        border: '1px solid #E9E9E9',
        gridColumnStart: 3,
        gridRowStart: 2
      }}>
        <div style={{display:"flex", flexDirection:"column", rowGap:"1rem"}}>
      <p style={{color : "#0065A6"}}>Step 2</p>
      <h3 style={{color:"#00263E", fontWeight:"bold"}}>Review and Analyze</h3>
      <p style={{color:"#5D5D5D"}}>It proceeds to meticulously review the information provided in the healthcare provider's note. It's like having a seasoned expert at your fingertips, sifting through the details and unraveling the complexities to reveal valuable insights.</p>
      </div>
      </div>

      {/* Item 3 */}
      <div></div> {/* Empty div for spacing */}
      <div style={{
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnStart: 2,
        gridRowStart: 3,
        zIndex: 1
      }}><p style={{color:"#8C8C8C"}}>3</p></div>
      <div style={{
        padding: '25px',
        borderRadius:"15px",
        border: '1px solid #E9E9E9',
        gridColumnStart: 1,
        gridRowStart: 3
      }}><div style={{display:"flex", flexDirection:"column", rowGap:"1rem"}}>
      <p style={{color : "#0065A6"}}>Step 3</p>
      <h3 style={{color:"#00263E", fontWeight:"bold"}}>Precision Prediction</h3>
      <p style={{color:"#5D5D5D"}}>Armed with a wealth of knowledge, NoteDoctor.AI predicts the correct ICD 11 code with unparalleled precision. It also provides supporting data for diagnosis, empowering you with comprehensive information to make informed decisions.</p>
      </div></div>

      {/* Item 4 */}
      <div></div> {/* Empty div for spacing */}
      <div style={{
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnStart: 2,
        gridRowStart: 4,
        zIndex: 1
      }}><p style={{color:"#8C8C8C"}}>4</p></div>
      <div style={{
       padding: '25px',
       borderRadius:"15px",
       border: '1px solid #E9E9E9',
        gridColumnStart: 3,
        gridRowStart: 4
      }}>
        <div style={{display:"flex", flexDirection:"column", rowGap:"1rem"}}>
      <p style={{color : "#0065A6"}}>Step 4</p>
      <h3 style={{color:"#00263E", fontWeight:"bold"}}>Summarizing for Clarity</h3>
      <p style={{color:"#5D5D5D"}}>Provider notes can be overwhelming, but fret not – NoteDoctor.AI summarizes the essential information, making it easily digestible for coders by ensuring clarity and focus amidst the complexity.</p>
      </div>
      </div>
      {/* Item 5 */}
      <div></div> {/* Empty div for spacing */}
      <div style={{
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnStart: 2,
        gridRowStart: 5,
        zIndex: 1
      }}><p style={{color:"#8C8C8C"}}>5</p></div>
      <div style={{
        padding: '25px',
        borderRadius:"15px",
        border: '1px solid #E9E9E9',
        gridColumnStart: 1,
        gridRowStart: 5
      }}>
        <div style={{display:"flex", flexDirection:"column", rowGap:"1rem"}}>
      <p style={{color : "#0065A6"}}>Step 5</p>
      <h3 style={{color:"#00263E", fontWeight:"bold"}}>Export with Ease</h3>
      <p style={{color:"#5D5D5D"}}>Once the coding masterpiece is complete, NoteDoctor.AI offers seamless export capabilities in PDF or Word format.. Share your findings with ease, whether it's for collaboration, review, or presentation purposes.</p>
      </div>
      </div>
    </div>
    <div className={styles.mainGridHowMobile} >
      {/* Vertical dashed line */}
      <div className={styles.dashedLineMobile} style={{
  position: 'absolute',
  top: 0,
  left: '10%',
  borderLeft: '2px dashed #D9D9D9',
  height: 'calc((185px * 5) + (20px * 4))', // Example height for 5 items and 4 gaps
  zIndex: 0
}}></div>
      
     
      <div style={{
        marginLeft:"88%",
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnStart: 1,
        gridRowStart: 1,
        zIndex: 1,
        
        
      }}><p style={{color:"#8C8C8C"}}>1</p></div>
      <div style={{
        padding: '25px',
        borderRadius:"15px",
        border: '1px solid #E9E9E9',
        gridColumnStart: 2,
        gridRowStart: 1,
        
      }}>
        <div style={{display:"flex", flexDirection:"column", rowGap:"1rem"}}>
        <p style={{color : "#0065A6"}}>Step 1</p>
        <h3 style={{color:"#00263E", fontWeight:"bold"}}>Effortless Upload</h3>
        <p style={{color:"#5D5D5D"}}>With NoteDoctor.AI's drag and drop system, uploading files for processing is a breeze. Simply drag your files into the designated area, sit back, and let the magic unfold. It's like having a personal assistant handle the paperwork, freeing up your time for more important tasks.</p>
        </div>
      </div>

      
      <div style={{
         marginLeft:"88%",
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnStart: 1,
        gridRowStart: 2,
        zIndex: 1
      }}><p style={{color:"#8C8C8C"}}>2</p></div>
      <div style={{
        padding: '25px',
        borderRadius:"15px",
        border: '1px solid #E9E9E9',
        gridColumnStart: 2,
        gridRowStart: 2
      }}>
        <div style={{display:"flex", flexDirection:"column", rowGap:"1rem"}}>
      <p style={{color : "#0065A6"}}>Step 2</p>
      <h3 style={{color:"#00263E", fontWeight:"bold"}}>Review and Analyze</h3>
      <p style={{color:"#5D5D5D"}}>It proceeds to meticulously review the information provided in the healthcare provider's note. It's like having a seasoned expert at your fingertips, sifting through the details and unraveling the complexities to reveal valuable insights.</p>
      </div>
      </div>

     
      <div style={{
         marginLeft:"88%",
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnStart: 1,
        gridRowStart: 3,
        zIndex: 1
      }}><p style={{color:"#8C8C8C"}}>3</p></div>
      <div style={{
        padding: '25px',
        borderRadius:"15px",
        border: '1px solid #E9E9E9',
        gridColumnStart: 2,
        gridRowStart: 3
      }}><div style={{display:"flex", flexDirection:"column", rowGap:"1rem"}}>
      <p style={{color : "#0065A6"}}>Step 3</p>
      <h3 style={{color:"#00263E", fontWeight:"bold"}}>Precision Prediction</h3>
      <p style={{color:"#5D5D5D"}}>Armed with a wealth of knowledge, NoteDoctor.AI predicts the correct ICD 11 code with unparalleled precision. It also provides supporting data for diagnosis, empowering you with comprehensive information to make informed decisions.</p>
      </div></div>

      
      <div style={{
         marginLeft:"88%",
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnStart: 1,
        gridRowStart: 4,
        zIndex: 1
      }}><p style={{color:"#8C8C8C"}}>4</p></div>
      <div style={{
       padding: '25px',
       borderRadius:"15px",
       border: '1px solid #E9E9E9',
        gridColumnStart: 2,
        gridRowStart: 4
      }}>
        <div style={{display:"flex", flexDirection:"column", rowGap:"1rem"}}>
      <p style={{color : "#0065A6"}}>Step 4</p>
      <h3 style={{color:"#00263E", fontWeight:"bold"}}>Summarizing for Clarity</h3>
      <p style={{color:"#5D5D5D"}}>Provider notes can be overwhelming, but fret not – NoteDoctor.AI summarizes the essential information, making it easily digestible for coders by ensuring clarity and focus amidst the complexity.</p>
      </div>
      </div>
      
      <div style={{
        width: '30px',
        
        marginLeft:"88%",
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
        border: '1px solid #ccc',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        gridColumnStart: 1,
        gridRowStart: 5,
        zIndex: 1
      }}><p style={{color:"#8C8C8C"}}>5</p></div>
      <div style={{
        padding: '25px',
        borderRadius:"15px",
        border: '1px solid #E9E9E9',
        gridColumnStart: 2,
        gridRowStart: 5
      }}>
        <div style={{display:"flex", flexDirection:"column", rowGap:"1rem"}}>
      <p style={{color : "#0065A6"}}>Step 5</p>
      <h3 style={{color:"#00263E", fontWeight:"bold"}}>Export with Ease</h3>
      <p style={{color:"#5D5D5D"}}>Once the coding masterpiece is complete, NoteDoctor.AI offers seamless export capabilities in PDF or Word format.. Share your findings with ease, whether it's for collaboration, review, or presentation purposes.</p>
      </div>
      </div>
    </div>
     

      
        <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", rowGap:"2rem",marginBottom:"5rem",padding:"2rem"}}>
          <h2 style={{fontSize:"2rem", textAlign:"center"}}>Ready to Experience the Magic?</h2>
          <p style={{color:"#003353", textAlign:"center"}}>
            Embark on your coding journey with NoteDoctor.AI and unlock the full
            potential of your coding workflow. Say goodbye to complexity and
            hello to seamless efficiency, accuracy, and productivity.
          </p>
          <ContactUsFrom />
          {/* <Button
            style={{
              borderRadius: "10px",
              padding: "0.4rem 1.5rem 0.4rem 1.5rem",
            }}
            variant="contained"
          >
            Get Started
          </Button> */}
        </div>
      
    </>
  );
}

