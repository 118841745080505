import React from "react";
import styles from "./index.module.css"
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import AddIcon from '@mui/icons-material/Add';
import { Button } from "@mui/material";
import waveback from "../assests/howitworkswave.png"
import { useNavigate } from "react-router-dom";
export default function FAQPage() {
  const navigate = useNavigate();
  return (
    <>
    <div className={styles.bannerContainer} style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", marginTop:"6rem",marginBottom:"5rem",margin:"auto",backgroundImage: `url(${waveback})`, 
  backgroundSize: "cover", 
  backgroundPosition: "center", }}>
      <h2 style={{fontSize:"2rem", color:"#001A2E",marginBottom:"3rem", marginTop:"3rem"}}>Frequently Asked Questions</h2>
      {/* <p style={{fontSize:"1rem", color:"#003353", marginBottom:"5rem"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol</p> */}
      </div>
    <div className={styles.AccordionContainer} style={{display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", marginTop:"5rem",marginBottom:"5rem", maxWidth:"50%",margin:"auto"}}>
      
       <div style={{width:"90%", margin:"auto"}}>
       <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
        >
          What is NoteDoctor.AI?
        </AccordionSummary>
        <AccordionDetails>
         NoteDoctor.AI is an AI-powered platform designed to revolutionize medical coding. Our platform analyzes provider notes, predicts accurate ICD 11 codes, and streamlines the coding process for healthcare organizations.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
        >
         How does NoteDoctor.AI work?
        </AccordionSummary>
        <AccordionDetails>
        NoteDoctor.AI utilizes advanced artificial intelligence technology to analyze provider notes and predict the correct ICD 11 codes. Our platform streamlines workflows, provides coding suggestions, and supports comprehensive documentation for accurate and efficient medical coding.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
        >
         Is NoteDoctor.AI HIPAA-compliant?
        </AccordionSummary>
        <AccordionDetails>
        Yes, NoteDoctor.AI is HIPAA-compliant. We prioritize the security and confidentiality of patient data and adhere to industry regulations to ensure compliance with HIPAA standards.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
        >
          How does NoteDoctor.AI ensure the security of my data?        
          </AccordionSummary>
        <AccordionDetails>
        NoteDoctor.AI employs robust security measures, including encryption…….</AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
                  >
          What industry regulations does NoteDoctor.AI comply with?        
          </AccordionSummary>
        <AccordionDetails>
        NoteDoctor.AI complies with industry regulations and standards, including HIPAA (Health Insurance Portability and Accountability Act), GDPR (General Data Protection Regulation), HITECH Act, and PCI DSS (Payment Card Industry Data Security Standard), where applicable.        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
        >
         How long does it take to process a note with NoteDoctor.AI?
        </AccordionSummary>
        <AccordionDetails>
        Processing a note with NoteDoctor.AI typically takes less than 10 seconds, allowing for rapid and efficient coding.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
        >
         Can I export information from NoteDoctor.AI in PDF or Word format?
        </AccordionSummary>
        <AccordionDetails>
         Yes, NoteDoctor.AI allows you to export information in PDF or Word format for easy sharing and documentation purposes.
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
        >
        Does NoteDoctor.AI offer coding suggestions?
        </AccordionSummary>
        <AccordionDetails>
        Yes, NoteDoctor.AI provides coding suggestions based on the analysis of provider notes, supporting data, and predictive coding accuracy.        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
        >
         What pricing plans are available for NoteDoctor.AI?
        </AccordionSummary>
        <AccordionDetails>
         Please contact our sales team to inquire about pricing plans and licensing options tailored to your organization's needs.        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
        >
         Is there a free trial available for NoteDoctor.AI?
        </AccordionSummary>
        <AccordionDetails>
         Yes, we offer a free trial of NoteDoctor.AI to eligible organizations. Please contact us to learn more and request a trial.</AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<AddIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          style={{color:"#00192A"}}
        >
         Are there any hidden fees with NoteDoctor.AI
        </AccordionSummary>
        <AccordionDetails>
         No, there are no hidden fees with NoteDoctor.AI. Our pricing is transparent, and we strive to provide clear and concise information about our pricing plans and services.</AccordionDetails>
      </Accordion>
      </div>
      <div style={{display:"flex",flexDirection:"column", alignItems:"center",justifyContent:"center", marginTop:"7rem",marginBottom:"5rem",rowGap:"20px",textAlign: "center"}}>
        <p style={{color:"#2F2F2F", textAlign:"center"}}>If you have any additional questions or inquiries that are not addressed in our FAQ, please don't hesitate to reach out to us. Our team is here to assist you and provide you with the information you need.</p>
        <Button onClick={() => navigate("/contactus")} style={{borderRadius:"15px",padding:"1rem 3rem 1rem 3rem"}} variant="contained">Contact Us</Button>
      </div>
      </div>
    </>
  );
}
