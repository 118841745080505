import React from "react";
import styles from "./index.module.css";

import WaveBack from "../assests/howitworkswave.png";
import { Button } from "@mui/material";
export default function SolutionPage() {
  return (
    <>
     <div style={{  
        backgroundImage: `url(${WaveBack})`, 
        backgroundSize: "cover", 
        backgroundPosition: "center", 
        width: "100%", }}>
        <div>
          <div>
            <div style={{display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", padding :"4rem"}}>
              <h2 style={{fontSize:"2rem"}}>Solution</h2>
              {/* <p style={{color:"#003353", fontSize:"16px", textAlign:"center"}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dol</p> */}

            </div>
          </div>
        </div>
      </div>

      <div style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" ,marginBottom:"3rem"}}>
  <div style={{ width: "100%" }}>
    <div style={{ width: "100%" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <ul className={styles.ulSolutions} style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)', // Sets up three equal columns
          gridGap: '20px', // Sets space between grid items
          padding: '0', // Removes default padding
          maxWidth: '1200px', // Optional: restricts the maximum width of the grid
          margin: 'auto',
          marginBottom:"3rem" // Center the grid horizontally
        }}>
         <li style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", backgroundColor:"#F5F5F5",padding:"1.3rem" , borderRadius:"15px", rowGap:"1rem"}}>
  <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{
      height: "10px",
      width: "10px",
      backgroundColor: "black",
      borderRadius: "50%",
      marginRight: "10px"
    }}></span>
    <p style={{color:"#00263E"}}><strong>Predictive Coding Accuracy</strong></p>
  </div>
  <p style={{color:"#5D5D5D", lineHeight:"30px"}}> Experience coding accuracy like never before with NoteDoctor.AI. Our AI-powered platform analyzes provider notes with precision, predicting the correct ICD 11 codes and minimizing errors. Ensure comprehensive documentation and maximize revenue potential with our advanced solution...</p>
</li>

<li style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", backgroundColor:"#F5F5F5",padding:"1.3rem" , borderRadius:"15px", rowGap:"1rem"}}>          <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{
      height: "10px",
      width: "10px",
      backgroundColor: "black",
      borderRadius: "50%",
      marginRight: "10px"
    }}></span>
            <p style={{color:"#00263E"}}><strong>Hospital Status Accuracy</strong></p>
            </div>
            <p style={{color:"#5D5D5D", lineHeight:"30px"}}>NoteDoctor.AI can help accurately determine inpatient vs observation status for hospital settings with data to support it. Avoid long delays with insurance companies as the first pass will have the data to support hospital status.</p>
          </li>
          <li style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", backgroundColor:"#F5F5F5",padding:"1.3rem" , borderRadius:"15px", rowGap:"1rem"}}>          <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{
      height: "10px",
      width: "10px",
      backgroundColor: "black",
      borderRadius: "50%",
      marginRight: "10px"
    }}></span>
            <p style={{color:"#00263E"}}><strong>Streamlined Workflow</strong></p>
            </div>
            <p style={{color:"#5D5D5D", lineHeight:"30px"}}>Optimize your coding workflow with NoteDoctor.AI's streamlined solution. From rapid note processing to intuitive coding suggestions, our platform simplifies the entire coding process. Say goodbye to manual tasks and hello to efficiency and productivity.</p>
          </li>
          <li style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", backgroundColor:"#F5F5F5",padding:"1.3rem" , borderRadius:"15px", rowGap:"1rem"}}>          <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{
      height: "10px",
      width: "10px",
      backgroundColor: "black",
      borderRadius: "50%",
      marginRight: "10px"
    }}></span>
            <p style={{color:"#00263E"}}><strong>Real-Time Feedback and Suggestions</strong></p>
            </div>
            <p style={{color:"#5D5D5D", lineHeight:"30px"}}>With NoteDoctor.AI, you're never alone in the coding process. It provides real-time feedback and coding suggestions, empowering you to make informed decisions and produce comprehensive documentation. Improve coding accuracy and efficiency with our intelligent solution.</p>
          </li>
          <li style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", backgroundColor:"#F5F5F5",padding:"1.3rem" , borderRadius:"15px", rowGap:"1rem"}}>          <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{
      height: "10px",
      width: "10px",
      backgroundColor: "black",
      borderRadius: "50%",
      marginRight: "10px"
    }}></span>
            <p style={{color:"#00263E"}}><strong>Continuous Improvement and Updates</strong></p>
            </div>
            <p style={{color:"#5D5D5D", lineHeight:"30px"}}>At NoteDoctor.AI, we're committed to continuous improvement and innovation. Our platform is regularly updated with the latest advancements in AI technology and coding practices to ensure maximum effectiveness and accuracy. Stay ahead of the curve with NoteDoctor.AI's cutting-edge solutions.</p>
          </li>
          <li style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", backgroundColor:"#F5F5F5",padding:"1.3rem" , borderRadius:"15px", rowGap:"1rem"}}>          <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{
      height: "10px",
      width: "10px",
      backgroundColor: "black",
      borderRadius: "50%",
      marginRight: "10px"
    }}></span>
            <p style={{color:"#00263E"}}><strong>Scalability for Growth</strong></p>
            </div>
            <p style={{color:"#5D5D5D", lineHeight:"30px"}}>Whether you're a small clinic or a large healthcare system, NoteDoctor.AI scales to meet your needs. Our platform is designed to accommodate growth, allowing you to expand your operations without sacrificing efficiency or accuracy. Scale your coding capabilities with confidence with NoteDoctor.AI.</p>
          </li>
          <li style={{ display: "flex", flexDirection: "column", alignItems: "flex-start", textAlign: "left", backgroundColor:"#F5F5F5",padding:"1.3rem" , borderRadius:"15px", rowGap:"1rem"}}>          <div style={{ display: "flex", alignItems: "center" }}>
    <span style={{
      height: "10px",
      width: "10px",
      backgroundColor: "black",
      borderRadius: "50%",
      marginRight: "10px"
    }}></span>
            <p style={{color:"#00263E"}}><strong>Explore the full potential of medical coding with NoteDoctor.AI.</strong></p>
            </div>
            <p style={{color:"#5D5D5D", lineHeight:"30px"}}>Sign up now to revolutionize your coding processes and transform your healthcare organization.</p>
            <Button onClick={() => window.location.href = "https://healthapp0.azurewebsites.net/.auth/login/aad/callback"} variant="outlined">Get Started Today</Button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>

      

    </>
  );
}
