import React from "react";
import waveback from "../assests/howitworkswave.png";
import ContactUsFrom from "../common/ContactUsFrom";
// import { FaPhone } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
// import { IoLocationSharp } from "react-icons/io5";
// import { FaTwitter } from "react-icons/fa";
// import { IoLogoFacebook } from "react-icons/io5";
// import { FiInstagram } from "react-icons/fi";
import { SiLinkedin } from "react-icons/si";
import gradientBack from "../assests/Gradient.png";
import styles from "./index.module.css"
export default function ContactUsPage() {
    return(
        <>
        <div style={{display:"flex", flexDirection:"column", margin:"auto", alignItems:"center", justifyContent:"center",maxWidth:"1200px"}}>
          <div style={{display:"flex",rowGap:"3rem",textAlign:"center", flexDirection:"column", alignItems:"center", justifyContent:"center", marginTop:"4rem", marginBottom:"6rem",backgroundImage: `url(${waveback})`,backgroundSize: "cover", backgroundPosition: "center",width:"100%"}}>
            <h2 style={{fontSize:"2rem",color:"#001A2E"}}>Contact Us</h2>
            <p style={{color:"#003353", fontSize:"1.1rem"}}>You can’t find what you are looking for?<br></br>
              We can help.</p>
            </div>
            <div className={styles.cartContainer} style={{display:"flex", flexDirection:"row", alignItems:"center",justifyContent:"center", backgroundColor:"#FBFBFB",marginBottom:"9rem"}}>
                <div><ContactUsFrom /></div>
                
                <div className={styles.visitCard} style={{
      background: `url(${gradientBack}), linear-gradient(to right, #007BFF, #228DD2)`,  // PNG on top of the gradient
      backgroundSize: 'cover, cover',  // Covers the div completely with both the image and gradient
      backgroundPosition: 'center, center',  // Centers both the image and gradient
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      justifyContent: "space-between",
      padding: "40px",
      height: "100%",
      width: "60%",
      color: "#FFF",
      borderRadius:"15px"
    }}>
      <div style={{display:"flex", flexDirection:"column", rowGap:"1rem", marginBottom:"16rem"}}>
        <h3 style={{color:"white", fontSize:"2rem", marginBottom:"1.8rem", textAlign:"left"}}>Contact Information</h3>
        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", columnGap:"10px" }}><FaPhone size={25}/> <span style={{fontSize:"1.1rem"}}>120876223445</span></div> */}
        <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", columnGap:"10px" }}><MdEmail size={25}/> <span style={{fontSize:"1.1rem"}}>Sales@notedoctor.ai</span></div>
        {/* <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center", columnGap:"10px" }}><IoLocationSharp size={25}/><span style={{fontSize:"1.1rem"}}>Lorem ipsum dolor sit amet, consectetur adipiscing .</span></div> */}
      </div>
      <div style={{ display: "flex", flexDirection: "row", columnGap:"1rem" }}>
        {/* <FaTwitter  size={30} style={{ marginRight: "10px" }} />
        <IoLogoFacebook  size={30} style={{ marginRight: "10px" }} />
        <FiInstagram size={30}  style={{ marginRight: "10px" }} /> */}
        <a href="https://www.linkedin.com/company/notedoctor-ai/about/?viewAsMember=true" target="_blank" rel="noopener noreferrer"><SiLinkedin size={30} /></a>
        
      </div>
    </div>
                </div>  
        </div>
        </>
    )
}