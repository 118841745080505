import React from "react";

import styles from "./index.module.css";
import waveback from "../assests/howitworkswave.png";

export default function SecurityPage() {
  return (
    <>
      <div style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <div className={styles.securityBanner} style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center",backgroundImage: `url(${waveback})`, 
  backgroundSize: "cover", 
  backgroundPosition: "center",marginTop:"6rem",rowGap:"1rem",marginBottom:"4rem" }}>
            <h2 style={{fontSize:"2rem", color:"#001A2E"}}>Data Security and Compliance</h2>
            <p style={{fontSize:"1.3rem", color:"#003353", width:"60%"}}>
              At NoteDoctor.AI, safeguarding your data is our top priority. We
              understand the critical importance of data security in the
              healthcare industry and are committed to maintaining the highest
              standards of protection for your sensitive information.
            </p>
          </div>
       <div className={styles.securityItems} style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center", marginTop:"4rem",rowGap:"3rem",maxWidth:"50%"}}>
        <div style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <h3 style={{fontSize:"1.6rem", color:"#00263E",marginBottom:"1.5rem"}}>Compliance with Industry Regulations</h3>
        <p style={{fontSize:"1rem", color:"#5D5D5D",textAlign:"center"}}>In addition to HIPAA and GDPR compliance, NoteDoctor.AI adheres to other relevant industry regulations and standards, including:</p>
        </div>
        <div style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <h3 style={{fontSize:"1.6rem", color:"#00263E",marginBottom:"1.5rem"}}>HITECH Act</h3>
        <p style={{fontSize:"1rem", color:"#5D5D5D", textAlign:"center"}}>We comply with the Health Information Technology for Economic and Clinical Health (HITECH) Act, which addresses the security and privacy concerns associated with the electronic transmission of health information.</p>
        </div>
        <div style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <h3 style={{fontSize:"1.6rem", color:"#00263E",marginBottom:"1.5rem"}}>PCI DSS</h3>
        <p style={{fontSize:"1rem", color:"#5D5D5D", textAlign:"center"}}>We adhere to the Payment Card Industry Data Security Standard (PCI DSS) for organizations that handle payment card data, ensuring secure payment transactions.</p>
        </div>
        <div style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <h3 style={{fontSize:"1.6rem", color:"#00263E",marginBottom:"1.5rem"}}>Data Encryption</h3>
        <p style={{fontSize:"1rem", color:"#5D5D5D", textAlign:"center"}}>All data stored and transmitted through NoteDoctor.AI is encrypted using strong encryption algorithms to protect it from unauthorized access. Encryption ensures that your data remains confidential and secure, both in transit and at rest.</p>
        </div>
        <div style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <h3 style={{fontSize:"1.6rem", color:"#00263E",marginBottom:"1.5rem"}}>User Privacy</h3>
        <p style={{fontSize:"1rem", color:"#5D5D5D", textAlign:"center"}}>We respect your privacy and are committed to transparent data practices. We do not collect or store any information.</p>
        </div>
        <div style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center"}}>
        <h3 style={{fontSize:"1.6rem", color:"#00263E",marginBottom:"1.5rem"}}>Your Trust is Our Priority</h3>
        <p style={{fontSize:"1rem", color:"#5D5D5D", textAlign:"center"}}>At NoteDoctor.AI, we recognize that trust is earned, and we are committed to earning and maintaining your trust through our unwavering dedication to data security and compliance.</p>
        </div>
        <div style={{display:"flex",flexDirection:"column", alignItems:"center", justifyContent:"center", marginBottom:"5rem"}}>
        <h3 style={{fontSize:"1.6rem", color:"#00263E",marginBottom:"1.5rem"}}>Get in Touch</h3>
        <p style={{fontSize:"1rem", color:"#5D5D5D", textAlign:"center"}}>If you have any questions, concerns, or feedback regarding our security practices, please don't hesitate to reach out to us. Our security team is here to assist you and address any inquiries you may have.
</p>
        </div>
       </div>
      </div>

      
    </>
  );
}
