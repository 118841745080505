import React,{useState} from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import styles from "../LandingPage/LandingBody.module.css";
import { sendEmail } from "../EmailFunction/sendEmail";
const cls = {
  textField: { ".MuiInputBase-root": { borderRadius: "10px", minWidth:"300px" } },
  sendBtn: {
    alignSelf: "flex-end",
    backgroundColor: "#228DD2",
    borderRadius: "10px",
    padding: "13px 50px",
    fontWeight: "500",
    fontSize: "18px",
    textTransform: "none",
    marginLeft: "auto",
  },
};

export default function ContactUsFrom() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [description, setDescription] = useState('');
  const [isSending, setIsSending] = useState(false);
  const formSubmitHandler = async (e) => {
    e.preventDefault();
    setIsSending(true);  // Disable the button by setting isSending to true
    try {
      const result = await sendEmail({ toEmail: email, subject: "contact form", message: description , firstName: firstName, lastName : lastName});
      if (result === "Email sent successfully") {
        alert('Email sent successfully');
      } else {
        alert('Failed to send email');
      }
    } catch (error) {
      alert('Error sending email');
      console.error(error);
    }
    setIsSending(false);  // Re-enable the button by setting isSending to false
  };
  return (
    <form
      onSubmit={formSubmitHandler}
      className={styles.formWrapper}
      id="contact-form"
    >
      <div className={styles.formItemContainer}>
        <div className={styles.ItemContainerInput}>
          <label htmlFor="firstName">
            <img src="/icons/user.svg" alt="" />
            First Name*
          </label>
          <TextField
            sx={cls.textField}
            required
            id="firstName"
            variant="outlined"
            placeholder="Your first name"
            value={firstName}
            onChange={(e)=>setFirstName(e.target.value)}
          />
        </div>

        <div className={styles.ItemContainerInput}>
          <label htmlFor="lastName">
            <img src="/icons/user.svg" alt="" />
            Last Name*
          </label>
          <TextField
            sx={cls.textField}
            required
            id="lastName"
            variant="outlined"
            placeholder="Your last name"
            value={lastName}
            onChange={(e)=>setLastName(e.target.value)}
          />
        </div>

        <div className={styles.ItemContainerInput}>
          <label htmlFor="email">
            <img src="/icons/directbox-default-2.svg" alt="" />
            Email*
          </label>
          <TextField
            sx={cls.textField}
            required
            id="email"
            variant="outlined"
            placeholder="example@email.com"
            value={email}
            onChange={(e)=>setEmail(e.target.value)}
          />
        </div>

        <div className={styles.ItemContainerInput}>
          <label htmlFor="phoneNumber">
            <img src="/icons/call.svg" alt="" />
            Phone Number*
          </label>
          <TextField
            sx={cls.textField}
            required
            id="phoneNumber"
            variant="outlined"
            placeholder="Your phone number"
            value={phone}
            onChange={(e)=>setPhone(e.target.value)}
          />
        </div>
        <div
          className={styles.ItemContainerInput}
          style={{ flexBasis: "100%" }}
        >
          <label htmlFor="description">
            <img src="/icons/document-text.svg" alt="" />
            Description*
          </label>
          <textarea
            required
            id="description"
            rows="4"
            cols="50"
            placeholder="A small description"
            value={description}
            onChange={(e)=>setDescription(e.target.value)}
          />
        </div>
        <Button className={styles.buttonContainer}
        variant="contained" 
        type="submit" 
        sx={cls.sendBtn}
        disabled={isSending}  
      >
        {isSending ? 'Sending...' : 'Send Message'}
      </Button>
      </div>
    </form>
  );
}
