import React from "react";
import { Button } from "@mui/material";
import styles from "./index.module.css";
import { useNavigate } from "react-router-dom";
export default function AboutUs() {
  const navigate = useNavigate();
  return (
    <div style={{display:"flex",flexDirection:"column",justifyContent: "center", alignItems: "center", marginTop:"4rem"}}>
     <div className={styles.blueBoxMobile} style={{padding :"2rem",background: "linear-gradient(180deg, #2A5C7C, #163342)",backgroundColor:"#2A5C7C", display:"flex", flexDirection:"column",justifyContent: "center",rowGap:"1rem", alignItems: "center", maxWidth:"50%", borderRadius:"15px"}}>
      <h2 style={{color:"#FBFBFB", fontSize:"2rem"}}>About us</h2>
      <h3 style={{color:"#FBFBFB", fontSize:"1.2rem"}}>Bringing Change, Making Waves</h3>
      <p style={{color:"#FBFBFB", fontSize:"1rem", textAlign:"center"}}>Through AI-driven applications, we are bringing change and making waves in the healthcare systems. With a steadfast commitment to precision, innovation, and efficiency, we are redefining the paradigm of medical coding. We are empowering healthcare through innovation and excellence.</p>
     </div>
     <div className={styles.gridContainer} style={{
      display: 'grid',
      gridTemplateColumns: '1fr 1fr', // Two columns
      gridTemplateRows: '1fr 1fr', // Two rows
      gap: '20px', // Space between grid items
      maxWidth: '50%', // Maximum width of the grid
      margin: 'auto', // Center the grid horizontally
      marginTop:"5rem",
      marginBottom:"5rem"
    }}>
      {/* Grid item 1 */}
      <div style={{
        
        padding: '20px',
        display: 'flex',
        flexDirection:"column",
        justifyContent: 'center',
        alignItems: 'center',
        border:"1px solid #115784",
        borderRadius:"10px",
        rowGap:"1.5rem"
      }}>
        <h3 style={{color:"#004C7D", fontSize:"1.4rem"}}>Who We Are</h3>
        <p style={{textAlign: "justify" ,textJustify: "inter-word", color:"#5D5D5D"}}>NoteDoctor.AI is a leading provider of AI-powered solutions for the healthcare industry. Founded with a vision to revolutionize medical coding, our team combines expertise in artificial intelligence, healthcare, and technology to deliver cutting-edge solutions that drive efficiency, accuracy, and profitability for healthcare organizations worldwide.</p>
      </div>

      {/* Grid item 2 */}
      <div style={{
        
        padding: '20px',
        display: 'flex',
        flexDirection:"column",
        justifyContent: 'center',
        alignItems: 'center',
        border:"1px solid #115784",
        borderRadius:"10px",
        rowGap:"1.5rem"
      }}>
        <h3 style={{color:"#004C7D", fontSize:"1.4rem"}}>Our Mission</h3>
        <p style={{textAlign: "justify" ,textJustify: "inter-word", color:"#5D5D5D"}}>At NoteDoctor.AI, our mission is to revolutionize the healthcare industry through the power of artificial intelligence. We are committed to empowering healthcare organizations with innovative solutions that enhance efficiency, accuracy, and profitability.</p>
      </div>

      {/* Grid item 3 */}
      <div style={{
        
        padding: '20px',
        display: 'flex',
        flexDirection:"column",
        justifyContent: 'center',
        alignItems: 'center',
        border:"1px solid #115784",
        borderRadius:"10px",
        rowGap:"1.5rem"
      }}>
        <h3 style={{color:"#004C7D", fontSize:"1.4rem"}}>Our Vision</h3>
        <p style={{textAlign: "justify" ,textJustify: "inter-word", color:"#5D5D5D"}}>Our vision is to create a future where healthcare providers can focus on what matters most – delivering high-quality care to patients. We envision a world where coding processes are streamlined, errors are minimized, and revenue cycle management is optimized.</p>
      </div>

      {/* Grid item 4 */}
      <div style={{
        
        padding: '20px',
        display: 'flex',
        flexDirection:"column",
        justifyContent: 'center',
        alignItems: 'center',
        border:"1px solid #115784",
        borderBottom :"1px solid #115784",
        borderRadius:"10px",
        rowGap:"1.5rem",
      }}>
        <h3 style={{color:"#004C7D", fontSize:"1.4rem"}}>Our Values</h3>
        <p style={{textAlign: "justify" ,textJustify: "inter-word", color:"#5D5D5D"}}>At NoteDoctor.AI, we are guided by a set of core values that define who we are and how we operate:</p>
        <p style={{textAlign: "justify" ,textJustify: "inter-word", color:"#5D5D5D"}}>Excellence - Integrity - Innovation
Collaboration - Impact</p>
      </div>
    </div>
    <div style={{display:"flex", flexDirection:"column",marginTop:"5rem", marginBottom:"3rem",justifyContent: 'center',
        alignItems: 'center'}}>
      <div className={styles.meetTeamContainer} style={{display:"flex", flexDirection:"column",justifyContent: 'center',
        alignItems: 'center',maxWidth:"50%", rowGap:"1rem"}}>
        <h3 style={{color:"#004C7D", fontSize:"1.4rem"}}>Meet Our Team</h3>
        <p style={{color:"#5D5D5D"}}>At the heart of NoteDoctor.AI is our dedicated team of professionals, each bringing their unique expertise and passion to the table. From AI specialists to healthcare industry veterans, our team is united by a common goal: to revolutionize healthcare through innovation and excellence.</p>
      </div>
    </div>
    <div style={{display:"flex",flexDirection:"column",justifyContent: 'center',
        alignItems: 'center', marginTop:"3rem", marginBottom:"3rem"}}>
          <div className={styles.teamContainer} style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: "3rem",
      width: '100%',
      maxWidth:"60%",
    }}>
      <div  className={styles.teamContentContainer} style={{
        width: '50%',  // Each child div takes 50% of the parent div
        padding: '0 20px'  // Optional padding for spacing inside the div
      }}>
        <h3 style={{ color: "#00263E", fontSize:"2rem" }}>Mitesh Patel M.D.</h3>
        <p style={{ color: "#5D5D5D", fontSize:"1rem", lineHeight:"30px" }}>With over a decade immersed in hospital environments, I've witnessed firsthand the adverse effects of inaccurate coding and hospital status on revenue streams and the vital task of patient care. Recognizing the toll it exacts on providers and Clinical Documentation Improvement (CDI) teams, I embarked on a mission to develop a solution.
        The inception of NoteDoctor.AI arose from a deep-seated commitment to empower healthcare professionals with tools that enhance both accuracy and efficiency, while liberating valuable time for patient interaction. My aspiration was to create an application that not only alleviates the complexities of coding queries but also enables CDI teams to navigate workflows with newfound ease and precision.</p>
      </div>
      <div style={{
        width: '50%',  // Each child div takes 50% of the parent div
        display: 'flex',
        justifyContent: 'center',  // Centers the content horizontally
        alignItems: 'center'  // Centers the content vertically
      }}>
        <p style={{
          backgroundColor: '#007BFF',  // Blue background
          color: 'white',  // White text color for contrast
          padding: '20px',  // Padding around the text for better visibility
          borderRadius: '50%',  // Makes the background rounded
          width: '100px',  // Specific width for the circle
          height: '100px',  // Specific height for the circle
          display: 'flex',
          justifyContent: 'center',  // Center text horizontally inside the circle
          alignItems: 'center',  // Center text vertically inside the circle
          fontSize: '1.5rem'  // Larger font size
        }}>Avatar Image</p>
      </div>
    </div>
    <div className={styles.teamContainerNd} style={{
      display: "flex",
      flexDirection: "row",
      justifyContent: 'center',
      alignItems: 'center',
      marginTop: "3rem",
      width: '100%',
      maxWidth:"60%"
    }}>
      
      <div style={{
        width: '50%',  // Each child div takes 50% of the parent div
        display: 'flex',
        justifyContent: 'center',  // Centers the content horizontally
        alignItems: 'center'  // Centers the content vertically
      }}>
        <p style={{
          backgroundColor: '#007BFF',  // Blue background
          color: 'white',  // White text color for contrast
          padding: '20px',  // Padding around the text for better visibility
          borderRadius: '50%',  // Makes the background rounded
          width: '100px',  // Specific width for the circle
          height: '100px',  // Specific height for the circle
          display: 'flex',
          justifyContent: 'center',  // Center text horizontally inside the circle
          alignItems: 'center',  // Center text vertically inside the circle
          fontSize: '1.5rem'  // Larger font size
        }}>Avatar Image</p>
      </div>
      <div className={styles.teamContentContainer} style={{
        width: '50%',  // Each child div takes 50% of the parent div
        padding: '0 20px'  // Optional padding for spacing inside the div
      }}>
        <h3 style={{ color: "#00263E", fontSize:"2rem" }}>Pinal Patel</h3>
        <p style={{ color: "#5D5D5D", fontSize:"1rem", lineHeight:"30px" }}>At NoteDoctor.AI, Pinal leads our technical initiatives, drawing from over a decade of experience in Technical Consulting with renowned organizations like HPE, IBM and esteemed NSA”s CAE-CD designated military institution UNG. Armed with a BS in Computer Science, he steers our company towards innovative solutions and technically excellence.</p>
      </div>
    </div>
    </div>
    <div className={styles.lastPartContainer} style={{display:"flex", flexDirection:"column",justifyContent: 'center',alignItems: 'center', maxWidth:"60%", rowGap:"2rem",marginTop:"5rem",marginBottom:"4rem"}}>
      <h3 style={{fontSize:"2rem", color:"#00263E"}}>Join Us on Our Journey</h3>
      <p style={{fontSize:"1rem", color:"#5D5D5D"}}>Join us on our journey to transform healthcare with NoteDoctor.AI. Whether you're a healthcare provider looking to optimize your coding processes or a technology enthusiast passionate about AI innovation, we invite you to partner with us in shaping the future of healthcare. Together, we can create a healthier, more efficient, and more sustainable world for all.</p>
    <Button onClick={() => navigate("/contactus")} style={{backgroundColor:"#228DD2", padding:"0.7rem 2rem 0.7rem 2rem"}} variant="contained">Contact Us</Button>
    </div>
    </div>
  );
}
