export const sendEmail = async (emailDetails) => {
    const dataEmail  = JSON.stringify(emailDetails)
    try {
        const response = await fetch('https://sendemaildoctorai.azurewebsites.net/api/sendemail?code=HFWBRLa2tP7T_A9GTIJ8O-kQ7JeVt2LymyhiXJSGobKlAzFuMOCfcg==', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: dataEmail
        });
    
        if (response.ok) {
            const data = await response.text(); 
            
            return data;
        } else {
            throw new Error('Failed to send email');
        }
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
};
