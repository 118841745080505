import { BrowserRouter, Route, Routes } from "react-router-dom";
import Layout from "./common/Layout";
import LandingPage from "./LandingPage";
import AboutUs from "./AboutUs";
import HowWorks from "./HowItWorks";
import ServicePage from "./ServicePage";
import SolutionPage from "./SolutionPage";
import Security from "./SecurityPage";
import FAQ from "./FAQPage";
import ContactUsPage from "./ContactUsPage";
export default function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<LandingPage />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/service-page" element={<ServicePage />} />
          <Route path="/how-it-works" element={<HowWorks />} />
          <Route path="/solution-page" element={<SolutionPage />} />
          <Route path="/security-page" element={<Security />} />
          <Route path="/faq-page" element={<FAQ />} />
          <Route path="/contactus" element={<ContactUsPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
