import React, { useState ,useEffect} from "react";
import { useNavigate , useLocation} from "react-router-dom";
import styles from "./Header.module.css";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdClose } from "react-icons/md";

export default function Header() {
  const location = useLocation();
    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    useEffect(() => {
      if (isOpen) {
          setIsOpen(false);  // Close the menu if it is open
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
    return (
        <div className={styles.outerDiv}>
            <div className={styles.containerDiv}>
              <div style={{cursor:"pointer"}}>
                <img onClick={() => navigate("/")} src="images/logo.webp" alt="logo" className={styles.logo} />
                </div>
                {!isOpen && (<div className={styles.burgerMenu} onClick={() => setIsOpen(!isOpen)}>
                    <GiHamburgerMenu size={30}/>
                </div>)}
                
                {isOpen && ( 
                <div className={styles.burgerMenu} onClick={() => setIsOpen(!isOpen)}>
                    <MdClose size={30}/>
                </div>)}
               
            </div>
          {isOpen && ( <div className={`${styles.menuDiv} ${isOpen ? styles.open : ''}`}>
                <span className={styles.link} onClick={() => navigate("/")}>Home</span>
                <span className={styles.link} onClick={() => navigate("/contactus")}>Contact Us</span>
                <span className={styles.link} onClick={() => navigate("/about-us")}>About Us</span>
                <span className={styles.link} onClick={() => navigate("/faq-page")}>FAQ</span>
                <span className={styles.link} onClick={() => navigate("/service-page")}>Services</span>
                <span className={styles.link} onClick={() => navigate("/solution-page")}>Solution</span>
                <span className={styles.link} onClick={() => navigate("/how-it-works")}>How it works?</span>
                <span className={styles.link} onClick={() => navigate("/security-page")}>Security</span>
                <button className={`${styles.btnMobile} text-white bg-[#228DD2]`} onClick={() => window.location.href = "https://healthapp0.azurewebsites.net/.auth/login/aad/callback"}>Get Started</button>
            </div>)}
             <div className={styles.menuDivDesktop}>
             <span className={styles.link} onClick={() => navigate("/security-page")}>Security</span>
                <span className={styles.link} onClick={() => navigate("/solution-page")}>Solutions</span>
                <span className={styles.link} onClick={() => navigate("/about-us")}>About</span>
                <span className={styles.link} onClick={() => navigate("/contactus")}>Contact</span>
                <span className={styles.link} onClick={() => navigate("/service-page")}>Services</span>
                <span className={styles.link} onClick={() => navigate("/solution-page")}>Solution</span>
                <span className={styles.link} onClick={() => navigate("/how-it-works")}>   How it works?</span>
                

               
            </div>
           

            <div className={styles.containerDiv}>
                <button className={styles.btn} onClick={() => navigate('/contactus')}>Connect Us</button>
                <button className={`${styles.btn} text-white bg-[#228DD2]`} onClick={() => window.location.href = "https://healthapp0.azurewebsites.net/.auth/login/aad/callback"}>Login</button>
            </div>
        </div>
    );
}
