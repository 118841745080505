import React from "react";
import { useNavigate } from "react-router-dom";

export default function Footer() {
  const navigate = useNavigate();
  const year = new Date().getFullYear();

  return (
    <div className="justify-center">
    <div className="mx-auto flex flex-wrap justify-center text-center" >
      <div className="flex flex-grow justify-evenly w-full">
        <div>
          <h2 className="text-lg font-semibold text-[#00233D]">Contact Us</h2>
          <div className="flex flex-col gap-1 items-center mt-1">
            {/* <img src="/icons/email.svg" alt="email" className="min-w-0" /> */}
            <p className="text-[#5D5D5D] text-base leading-[8px] m-0">
              Sales@notedoctor.ai
            </p>
          </div>
        </div>
        <div>
          <h2 className="text-lg font-semibold text-[#00233D]">Pages</h2>
          <div className="flex flex-col  items-center">
            <h4 style={{ cursor: "pointer" }} onClick={() => navigate("/solution-page")}>Solution</h4>
            <h4 style={{ cursor: "pointer" }} onClick={() => navigate("/service-page")}>Services</h4>
            <h4 style={{ cursor: "pointer" }} onClick={() => navigate("/about-us")}>About us</h4>
            <h4 style={{ cursor: "pointer" }} onClick={() => navigate("/contactus")}>Contact us</h4>
            <h4 style={{ cursor: "pointer" }} onClick={() => navigate("/how-it-works")}>How it works</h4>
            <h4 style={{ cursor: "pointer" }} onClick={() => navigate("/faq-page")}>FAQ</h4>
            <h4 style={{ cursor: "pointer" }} onClick={() => navigate("/security-page")}>Security</h4>
          </div>
        </div>
        <div>
          <img src="/images/logo.webp" alt="logo" className="max-w-[124px] mb-1.5" />
          {/* <img src="/images/logo-bottom.png" alt="logo" /> */}
          <div className="flex flex-col  items-center">
            <a href="https://www.linkedin.com/company/notedoctor-ai/about/?viewAsMember=true" target="_blank" rel="noopener noreferrer">
              <img src="/icons/linkedin.svg" alt="linkedin" />
            </a>
          </div>
        </div>
      </div>
    </div>
    <p className="p-6 text-center text-[#5D5D5Db3] text-base leading-[26px] border-t border-[#8C8C8C1a]">
      © {year} SamePage. All rights reserved
    </p>
  </div>
  );
}
