import React from "react";
import { useNavigate } from "react-router-dom";
import EastIcon from "@mui/icons-material/East";
import Button from "@mui/material/Button";
import styles from "./LandingBody.module.css";
import ContactUsFrom from "../common/ContactUsFrom";
import EfficiencyIcon from "../assests/EfficiencyIcon.png";
import Timely from  "../assests/Timely.png";
import Accuracy from "../assests/Accuracy.png";
import UserFriendly from "../assests/UserFriendly.png";
import NotesSummer from "../assests/notessummer.png";
import Flexibility from "../assests/Flexibility.png"
<link rel="icon" href="" />;

const cls = {
  contentWrapper:
    "max-w-[1172px] mx-auto w-full pt-[112px] mb-[80px] sm:pt-[274px] sm:mb-[170px] text-center",
  div1Description:
    styles.secondParagraph + " max-w-[600px] mx-auto mt-1 sm:mt-11",
  div1Btn: {
    backgroundColor: "#228DD2",
    marginTop: "32px",
    borderRadius: "10px",
    textTransform: "unset",
    height: "56px",
    padding: "0px 32px",
    fontSize: { xs: "14px", sm: "18px" },
  },
  iframe: styles.iframeCont + " max-w-[970px] w-[90%]",
  div3: styles.thirdPart + " my-[1rem] sm:my-[7rem] pt-[2rem] pb-[7rem]",
  forthTextContainer: styles.forthTextContainer + " max-w-[1200px] mx-auto",
  features:
    styles.features +
    " grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-16 mt-5 md:mt-16",
};

export default function LandingPage() {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    window.location.href =
      "https://healthapp0.azurewebsites.net/.auth/login/aad/callback";
  };

  return (
    <>
      <div className={styles.topBannerContainer}>
        <div className={cls.contentWrapper}>
          <p className={styles.doctoraiText + " max-w-[900px] mx-auto"}>
            Say goodbye to errors, delays, and revenue loss with &nbsp;
            <span className={styles.DoctorAi}>
              Notedoctor.AI
              <img
                className={styles.heartbeatLine}
                alt="heartbeat"
                src="/images/HeartBeat.png"
              />
            </span>
          </p>
          <p className={cls.div1Description}>
            Unlock the power of artificial intelligence to revolutionize medical
            coding!
          </p>

          <Button
            onClick={handleButtonClick}
            sx={cls.div1Btn}
            variant="contained"
            endIcon={<EastIcon />}
          >
            Experience the Future Today
          </Button>
        </div>
      </div>

      <div className={styles.secondPart}>
        <div className={styles.secondTextContainer}>
          <h2>Who we are?</h2>
          <p className="max-w-[500px]">
          Submit your medical report to our platform for a thorough analysis by our expert team. Rest assured, we'll provide you with a professional assessment meticulously tailored to your specific needs and concerns, ensuring you receive comprehensive insights into your health status.
          </p>
          <Button
            onClick={() => navigate("/how-it-works")}
            sx={cls.div1Btn}
            variant="contained"
            endIcon={<EastIcon />}
          >
            Learn More
          </Button>
        </div>
      </div>

      <div className={cls.div3}>
        <div className={styles.thirdTextContainer + " text-center"}>
          <h2>How it works?</h2>
          <p>
          Experience the ease of obtaining an exclusive analysis of your medical report in just three simple steps. By following our streamlined process, you can gain valuable insights tailored to your health profile.
          </p>
          <Button
            onClick={handleButtonClick}
            sx={cls.div1Btn}
            variant="contained"
            endIcon={<EastIcon />}
          >
            Experience the Future Today
          </Button>
          <div className={styles.imagesContainer + " mt-4 sm:mt-20 mb-20"}>
            <img alt="step1" src="/images/step1.png" />
            <img alt="step2" src="/images/step2.png" />
            <img alt="step3" src="/images/step3.png" />
          </div>
          <h2 className="mt-10">
We're more than just a coding solution</h2>
          <p>
          Discover how NoteDoctor platform seamlessly integrates AI-driven predictive coding, streamlined workflows, and robust support to optimize revenue cycle management. Join us on the journey to redefine healthcare excellence.
          </p>
        </div>
      </div>

      <div className={styles.formContainer + " p-0"}>
  <div className={cls.forthTextContainer + " p-4 md:p-20"}>
    <h2>Easy to Use Features</h2>
    <p>"Hello there! Have a medical question or need advice? Dr.AI is here to help! Whether you're curious about symptoms, treatments, or just looking for general health tips, feel free to reach out. Your virtual healthcare assistant is just a message away. Let's chat!"</p>
    <div className={cls.features}>
      <div className={styles.featureBox}>
        <span className={styles.icon}>
          <img alt="effiency" src={EfficiencyIcon} />
          </span>
        <hr/>

        <h3>Efficiency</h3>
        <p>Upload files for web applications seamlessly with our drag-and-drop system.</p>
      </div>
      <div className={styles.featureBox}>
        <span className={styles.icon}>
          <img alt="timely" src={Timely} />
          </span>
        <hr/>

        <h3>Timely</h3>
        <p>Processing of notes takes seconds, effectively reducing wait times</p>
      </div>
      <div className={styles.featureBox}>
        <span className={styles.icon}>
          <img alt="accuracy" src={Accuracy} />
          </span>
        <hr/>

        <h3>Accuracy</h3>
        <p>With its advanced algorithms, coding accuracy is improved and it offers suggestions for medical codes and enhanced data support for efficient diagnoses.</p>
      </div>
      <div className={styles.featureBox}>
        <span className={styles.icon}>
          <img alt="userfriendly" src={UserFriendly} />
          </span>
        <hr/>

        <h3>User-Friendly Interface</h3>
        <p>Simplicity equals maximum ease of use. Our platform offers a user-friendly and intuitive interface.</p>
      </div>
      <div className={styles.featureBox}>
        <span className={styles.icon}>
          <img alt="notesummer" src={NotesSummer} />
          </span>
        <hr/>

        <h3>Notes Summarization</h3>
        <p>Provider’s notes are summarized with all essential information needed for coders to review efficiently.</p>
      </div>
      <div className={styles.featureBox}>
        <span className={styles.icon}>
          <img alt="flexibility" src={Flexibility} />
          </span>
        <hr/>
        <h3>Flexibility</h3>
        <p>Seamlessly export information in PDF or Word formats.
          Notes Summarization
          </p>
      </div>

      
      

      
    </div>
  </div>
</div>


<div className={styles.secondPart + " mb-0"}>
  <div className={styles.whyDoctorTextContainer + " p-4 md:p-20"}>
    <div className="max-w-[1500px] mx-auto">
      <h2>Why Choose NoteDoctor.AI?</h2>
      <div  className={styles.whyDoctorFeatures}>
        <div className={styles.whyDoctorFeaturesItems}>
          <h3>01/</h3>
          <h3>Unparalleled Accuracy:</h3>
          <p>
            With our state-of-the-art AI technology, we deliver unparalleled accuracy in medical coding, significantly reducing errors and minimizing revenue loss.
          </p>
        </div>
        <div className={styles.whyDoctorFeaturesItems}>
          <h3>02/</h3>
          <h3>Efficiency Redefined:</h3>
          <p>
            Say goodbye to time-consuming manual coding processes. Our platform processes notes in seconds, freeing up valuable time for healthcare professionals to focus on patient care.
          </p>
        </div>
        <div className={styles.whyDoctorFeaturesItems}>
          <h3>03/</h3>
          <h3>Data Security:</h3>
          <p>
            Your data is our top priority. We adhere to the highest standards of security and confidentiality, ensuring that your information remains protected at all times.
          </p>
        </div>
        <div className={styles.whyDoctorFeaturesItems}>
          <h3>04/</h3>
          <h3>Cost-Effectiveness:</h3>
          <p>
            By improving coding accuracy and streamlining workflows, NoteDoctor.AI helps healthcare organizations save costs and optimize revenue streams.
          </p>
        </div>
      </div>
    </div>
  </div>
</div>


      <div className={styles.formContainer + " mt-0"}>
        <div className={styles.forthTextContainer + " text-center"}>
          <h2>Talk to Us</h2>
          <p style={{textAlign:"center", marginBottom:"3rem"}}>
          "Hello there! Have a medical question or need advice? Dr. AI is here to help! Whether you're curious about symptoms, treatments, or just looking for general health tips, feel free to reach out. Your virtual healthcare assistant is just a message away. Let's chat!"
          </p>
          <ContactUsFrom />
        </div>
      </div>
    </>
  );
}
