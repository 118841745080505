import React from "react";
import styles from "./index.module.css";
import WaveBack from "../assests/howitworkswave.png"
import services1 from "../assests/services1.png"
import services2 from "../assests/services2.png"
import services3 from "../assests/services3.png"
import services4 from "../assests/services4.png";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
export default function ServicePage() {
  const navigate = useNavigate();
  return (
    <>
     
       
            <div  style={{  
        backgroundImage: `url(${WaveBack})`, 
        backgroundSize: "cover", 
        backgroundPosition: "center", 
        width: "100%",
        marginTop:"4rem",
        marginBottom:"4rem",
        paddingBottom:"5rem" }}>
              <h2 style={{marginTop:"2rem", fontSize:"2rem",textAlign:"center"}}>Service</h2>
              <p>
                <h3 style={{color:"#003353", fontSize:"1rem",textAlign:"center"}}>Medical Coding Solutions Tailored to Your Needs </h3>
              </p>
        
       
      </div>

      <div style={{
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  width: "100%", 
  maxWidth: "1200px", 
  margin: "3rem auto", 
  rowGap:"2rem"
}}>
  <div className={styles.rowServiceContainer} style={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", 
    direction: "rtl", 
  }}>
     
    <div className={styles.rowServiceContentContainer} style={{
      width: "50%", 
      textAlign: "left" ,
      margin : "0rem 2rem"
    }}>
      <h3 style={{ color: "#00263E", fontSize: "1.4rem" }}>AI-Driven Review, Precision Coding and Hospital Status</h3>
      <p style={{
        color: "#5D5D5D",
        fontSize: "1rem",
        maxWidth: "100%", 
        textAlign: "justify", 
        direction: "ltr",
        lineHeight:"2rem",
        
        textJustify: "inter-word",
      }}>
        With 63% of errors being due to the hospital’s coding mistakes, and 44% due to inaccuracies in clinical documentation, our solution aims to eradicate this. With the use of our AI application, information from the provider's notes is reviewed to accurately predict the correct ICD-11 code and hospital status with supporting data diagnosis.
        In one simple step, the provider note is also summarized with important information and data for the coder to easily review.
      </p>
    </div>
    <div className={styles.rowServiceImageContainer} style={{
      width: "50%", 
      textAlign: "center",
      marginRight:"1rem" 
    }}>
      <img className={styles.rowServiceImage} src={services1} alt="services1" style={{ maxWidth: "100%", height: "auto" }} /> {/* Ensures the image is responsive and does not overflow its container */}
    </div>
  </div>
  <div className={styles.rowServiceContainer} style={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", // Ensures the flex row container takes full width
    direction: "ltr", // Right-to-left direction for content alignment
  }}>
    <div className={styles.rowServiceContentContainer} style={{
      width: "50%", 
      textAlign: "left" ,
      margin : "0rem 2rem"
    }}>
      <h3 style={{ color: "#00263E", fontSize: "1.4rem" }}>Revenue Cycle Optimization</h3>
      <p style={{
        color: "#5D5D5D",
        fontSize: "1rem",
        maxWidth: "85%", 
        textAlign: "justify", 
        direction: "ltr",
        lineHeight:"2rem",
        
        textJustify: "inter-word",
      }}>
With the US healthcare system losing up to $935 million every week, optimizing your revenue cycle performance with accurate and efficient medical coding is critical. Using our advanced algorithms, our application looks at every chart and note and identifies and rectifies coding mistakes, ensuring maximum revenue cycle.      </p>
    </div>
    <div className={styles.rowServiceImageContainer} style={{
      width: "50%", 
      textAlign: "center",
      marginRight:"1rem" 
    }}>
      <img className={styles.rowServiceImage} src={services2} alt="services2" style={{ maxWidth: "100%", height: "auto" }} /> {/* Ensures the image is responsive and does not overflow its container */}
    </div>
  </div>
  <div className={styles.rowServiceContainer} style={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", // Ensures the flex row container takes full width
    direction: "rtl", // Right-to-left direction for content alignment
  }}>
    <div className={styles.rowServiceContentContainer} style={{
      width: "50%", 
      textAlign: "left" ,
      margin : "0rem 2rem"
    }}>
      <h3 style={{ color: "#00263E", fontSize: "1.4rem" }}>Clinical Documentation Improvement</h3>
      <p style={{
        color: "#5D5D5D",
        fontSize: "1rem",
        maxWidth: "100%", 
        textAlign: "justify", 
        direction: "ltr",
        lineHeight:"2rem",
        
        textJustify: "inter-word",
      }}>
        Inaccuracies in clinical documentation account for a significant portion of coding errors. Our web application will give data to support coding, making it easier for your coders to use the data in the provider note.
      </p>
    </div>
    <div className={styles.rowServiceImageContainer} style={{
      width: "50%", 
      textAlign: "center",
      marginRight:"1rem" 
    }}>
      <img className={styles.rowServiceImage} src={services3} alt="services3" style={{ maxWidth: "100%", height: "auto" }} /> {/* Ensures the image is responsive and does not overflow its container */}
    </div>
  </div>
  <div className={styles.rowServiceContainer} style={{
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%", // Ensures the flex row container takes full width
    direction: "ltr", // Right-to-left direction for content alignment
  }}>
    <div className={styles.rowServiceContentContainer} style={{
      width: "50%", 
      textAlign: "left" ,
      margin : "0rem 2rem",
      display:"flex",
      flexDirection:"column",
      alignItems: "flex-start"
    }}>
      <h3 style={{ color: "#00263E", fontSize: "1.4rem", direction:"ltr" }}>ICD-11 Transition Support</h3>
      <p style={{
        color: "#5D5D5D",
        fontSize: "1rem",
        maxWidth: "85%", 
        textAlign: "justify", 
        direction: "ltr",
        lineHeight:"2rem",
        
        textJustify: "inter-word",
        marginBottom:"0.8rem"
      }}>
        With the International Classification of Disease (ICD) 11 having 4x more codes than ICD-10, we ensure that transitioning from ICD-10 to ICD-11 is smooth. With the myriad of challenges transitioning offers, compliance and accuracy are paramount. Our specialized transitioning support system services guide you through the processes, minimizing disruptions and a smooth workflow.
      </p>
      <Button onClick={() => navigate("/contactus")} variant="outlined">Get in Touch With Us</Button>
    </div>
    <div  className={styles.rowServiceImageContainer} style={{
      width: "50%", 
      textAlign: "center",
      marginRight:"1rem" 
    }}>
      <img className={styles.rowServiceImage} src={services4} alt="services4" style={{ maxWidth: "100%", height: "auto" }} /> {/* Ensures the image is responsive and does not overflow its container */}
    </div>
  </div>
</div>

      
    </>
  );
}
